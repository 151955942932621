













import { Component, Mixins } from 'vue-property-decorator';
import CarNotificationsPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-notifications-page-otel-log';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import NotificationHeader from '../components/notification-header.vue';
import NotificationFilters from '../components/notification-filters.vue';
import NotificationGrid from '../components/notification-grid.vue';

@Component({
    components: {
        PageWrapper,
        NotificationHeader,
        NotificationFilters,
        NotificationGrid,
    },
})
export default class NotificationPage extends Mixins(CarNotificationsPageOtelLogs) {}
