import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import NotificationsService, { NotificationsServiceS } from '../../car-notifications/notifications.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarNotificationsPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(NotificationsServiceS) notificationsService!: NotificationsService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarNotificationsPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarNotificationsPageOtelLogs.spanPrefix);
        this.notificationsService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarNotificationsPageOtelLogs.spanPrefix);
                this.endSpan(CarNotificationsPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.notificationsService.storeState) });
            });
    }
}
